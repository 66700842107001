import React from 'react'
import { Layout, Title } from '../components/index';
import { Link } from 'gatsby';
import './404.scss'
import {Helmet} from 'react-helmet';
import Favicon from '../img/favicon3.png';

export default function () {
    return (
        <Layout>
            <Helmet>
                <title>Ups! 404 - Umjetnička organizacija Kreativna Kuća</title>
                <meta name='title' content='Ups! 404 - Umjetnička organizacija Kreativna Kuća' />
                <meta name='description' content='KREATIVNA KUĆA je umjetnička organizacija osnovana 2018. godine koja se bavi objavljivanjem i distribucijom kvalitetnih književnih djela, izradom autorskih animiranih filmova i organiziranjem i provedbom kreativnih radionica i manifestacija vezanih za književnost i animirani film. Naša je misija povezivanje zbilje i mašte u originalne umjetničke izričaje i širenje prostora stvaralačke slobode. Osnivači i glavni provoditelji programa Umjetničke organizacije KREATIVNA KUĆA su višestruko nagrađivana književnica i scenaristica Nena Lončar i likovni umjetnik Marijan Lončar.' />
                <link rel='shortcut icon' type='image/x-icon' href={Favicon} />
                <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' />
            </Helmet>
            <div className='page-404'>
                <Title title='Ups!' subtitle='Podstranica ne postoji.' />
                <div className="404-button-wrapper"><Link to='/'><button className='button-home-404'>Početna</button></Link></div>
            </div>
        </Layout>
    )
}
